@mixin font-face($family: Arial, $type: '', $weight: normal, $style: normal, $display: swap) {
  @font-face {
    font-family: $family;
    src: url('./fonts/#{$family}/#{$family}-#{$type}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
    font-display: $display;
  }
}
@include font-face('Montserrat', 'Thin', 100);
@include font-face('Montserrat', 'ExtraLight', 200);
@include font-face('Montserrat', 'Light', 300);
@include font-face('Montserrat', 'Regular', 400);
@include font-face('Montserrat', 'Medium', 500);
@include font-face('Montserrat', 'SemiBold', 600);
@include font-face('Montserrat', 'Bold', 700);
@include font-face('Montserrat', 'ExtraBold', 900);

@include font-face('HelveticaNeueCyr', 'Light', 300);
@include font-face('HelveticaNeueCyr', 'Regular', 400);

$base_font_size: 20px;
$font_primary: 600 $base_font_size 'Montserrat';
