@import 'font';

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: #000000;
  color: #ffffff;
  font: $font_primary;
  //background: radial-gradient(ellipse 50% 40%, #E30912, #000000)
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  -webkit-text-fill-color: #000 !important;
}

.Toastify__toast {
  -webkit-tap-highlight-color: transparent;
  padding: 12px 8px;
}

.Toastify__toast-body {
  font-size: 14px!important;
  line-height: 1.3;
  font-weight: 400;
}

.Toastify__progress-bar {
  height: 2px!important;
}

.Toastify__toast--success {
  background-color: #606470!important;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #606470!important;
}

.Toastify__toast--info {
  background-color: #606470!important;
}

.Toastify__toast--info .Toastify__zoom-enter {
  display: none!important;
}

.Toastify__toast--info .Toastify__close-button {
  display: none!important;
}

.Toastify__toast--error {
  background-color: #982a44!important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #982a44!important;
}

@media (min-width: 2000px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
  }
}

@media (min-width: 1000px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
  }
}
