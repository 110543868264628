$border: #ffffff;
$shadow: rgba(255, 255, 255, 0.3);
$time: 2s;

.dots-not-active {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.dots {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid $shadow;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &::before {
    top: -4px;
    left: -4px;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border: 4px solid transparent;
    animation: $time * 2 dots-before infinite;
  }
  &::after {
    bottom: -4px;
    right: -4px;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border: 4px solid transparent;
    animation: $time * 2 dots-after $time infinite;
  }
}

@keyframes dots-before {
  25% {
    width: 100%;
    height: 0;
    border-top-color: $border;
    border-right-color: transparent;
  }
  50% {
    width: 100%;
    height: 100%;
    border-top-color: $border;
    border-right-color: $border;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: $border;
    border-right-color: $border;
  }
}

@keyframes dots-after {
  25% {
    width: 100%;
    height: 0;
    border-bottom-color: $border;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 100%;
    border-bottom-color: $border;
    border-left-color: $border;
  }
  75% {
    width: 100%;
    height: 100%;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@media (max-width: 2000px) {
  .dots-not-active {
    border: 4px solid transparent;
  }
  .dots {
    border: 4px solid $shadow;
    &::before {
      border: 4px solid transparent;
      top: -4px;
      left: -4px;
    }

    &::after {
      border: 4px solid transparent;
      bottom: -4px;
      right: -4px;
    }
  }
}

@media (max-width: 1000px) {
  .dots-not-active {
    border: 2px solid transparent;
  }
  .dots {
    border: 2px solid $shadow;
    &::before {
      border: 2px solid transparent;
      top: -2px;
      left: -2px;
    }

    &::after {
      border: 2px solid transparent;
      bottom: -2px;
      right: -2px;
    }
  }
}
